@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


.loader {
	width: 48px;
	height: 48px;
	border: 3px solid #FFF;
	border-radius: 50%;
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
  }
  .loader::after {
	content: '';  
	box-sizing: border-box;
	position: absolute;
	left: 0;
	top: 0;
	background: #FF3D00;
	width: 16px;
	height: 16px;
	transform: translate(-50%, 50%);
	border-radius: 50%;
  }
	  
  @keyframes rotation {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  } 


.scale-up-center {
	-webkit-animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
			
}
@-webkit-keyframes scale-up-center {
	0% {
	  -webkit-transform: scale(0.8);
			  transform: scale(0.8);
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	}
  }
  @keyframes scale-up-center {
	0% {
	  -webkit-transform: scale(0.8);
			  transform: scale(0.8);
	}
	100% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	}
  }

.scale-down-center {
	-webkit-animation: scale-down-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-down-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-down-center {
	0% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	}
	100% {
	  -webkit-transform: scale(0.8);
			  transform: scale(0.8);
	}
  }
  @keyframes scale-down-center {
	0% {
	  -webkit-transform: scale(1);
			  transform: scale(1);
	}
	100% {
	  -webkit-transform: scale(0.8);
			  transform: scale(0.8);
	}
  }



  
  /* For WebKit Browsers */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #ED8166;
}

::-webkit-scrollbar-thumb:hover {
  background: #E35936;
}

  

